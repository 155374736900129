import { CitiesInterface } from '../interfaces/cities.interface';
import { GpsClass } from './gps.class';
import { MarketingCityClass } from './marketing_city.class';

export class CityClass {
	id!: string;
	type!: string;
	name!: string;
	shortcut!: string;
	countryCode!: string;
	countryName!: string;
	continentName!: string;
	showInWizard!: boolean;
	excludedFromArrivalOrDeparture!: boolean;
	coverPageImageUrl!: string;
	panoramaImageUrl!: string;
	tripImageUrl!: string;
	wizardImageUrl!: string;
	gps!: GpsClass;
	marketingId!: string | null;
	airportConnectionRating!: number;
	wizardOrder!: number;
	slug!: string;
	parentKeywords!: null | string;
	childKeywords!: null | string;

	metaTitle!: string;
	metaDescription!: string;
	cityMainImage!: string;
	cityMainImageAltText!: string;

	marketingCity!: MarketingCityClass;
	added: boolean = false;

	fromJson(json: CitiesInterface): void {
		this.id = json.id;
		this.type = json.type;
		this.name = json?.attributes['name'];
		this.shortcut = json?.attributes['shortcut'];
		this.coverPageImageUrl = json?.attributes['cover-page-image-url'];
		this.marketingId = json?.attributes['marketing-city-id'] !== null ? json?.attributes['marketing-city-id'] : null;
		this.panoramaImageUrl = json?.attributes['panorama-image-url'];
		this.tripImageUrl = json?.attributes['trip-image-url'];
		this.wizardImageUrl = json?.attributes['wizard-image-url'];
		this.countryCode = json?.attributes['country-code'];
		this.countryName = json?.attributes['country-name'];
		this.continentName = json?.attributes['continent-name'];
		this.showInWizard = json?.attributes['show-in-wizard'];
		this.wizardOrder = json?.attributes['wizard-order'];
		this.slug = json?.attributes['slug'];
		this.metaTitle = json.attributes.details?.['meta-title'] ?? '';
		this.metaDescription = json?.attributes.details?.metadata ?? '';
		this.excludedFromArrivalOrDeparture = json?.attributes['excluded-from-arrival-or-departure'];
		this.airportConnectionRating = json?.attributes['airport-connection-rating'];
		this.childKeywords = json?.attributes['child-keywords'];
		this.parentKeywords = json?.attributes['parent-keywords'];
		this.gps = new GpsClass(json?.attributes.gps);
		this.cityMainImage = json?.attributes['city-main-image'];
		this.cityMainImageAltText = json?.attributes['city-main-image-alt-text'];
		this.marketingCity = new MarketingCityClass();
		if (json?.attributes.details) {
			this.marketingCity.fromJsonDetail(json?.attributes.details);
		}
	}

	getMarketingCity(): MarketingCityClass {
		return this.marketingCity;
	}

	setAdded(added: boolean): void {
		this.added = added;
	}

	getMainImage(width: number, height: number): string {
		if (!this.cityMainImage || typeof this.cityMainImage !== 'string') return '';

		// Extract the base URL and parameters
		const [baseUrl, params] = this.cityMainImage.split('/upload/');
		const parts = params.split('/');
		const transformationsIndex = parts.findIndex((part) => part.match(/v[0-9]+/));

		// Extract transformations and the rest of the URL
		let transformations = parts.slice(0, transformationsIndex).join(',');
		let restOfUrl = parts.slice(transformationsIndex).join('/');

		// Split transformations into an array
		const transformationArray = transformations.split(',');

		// Define new transformations with specified width and height
		const newTransformations = [`f_auto`, `fl_lossy`, `q_auto`, `w_${width}`, `h_${height}`];

		// Map to replace existing width and height transformations
		const updatedTransformations = transformationArray
			.map((trans) => {
				if (trans.startsWith('w_') || trans.startsWith('h_')) {
					return null; // Mark for removal
				}
				return trans;
			})
			.filter((trans) => trans !== null); // Remove existing width/height

		// Merge transformations ensuring unique values
		const mergedTransformations = new Set([...updatedTransformations, ...newTransformations]);

		// Reconstruct transformations string
		transformations = Array.from(mergedTransformations).join(',');

		const supportedFormatsRegex = /\.(png|jpg|jpeg)$/i;

		// Replace the supported image format with .webp
		restOfUrl = restOfUrl.replace(supportedFormatsRegex, '.webp');

		// Construct and return the new URL
		return `${baseUrl}/upload/${transformations}/${restOfUrl}`;
	}

	getCoverPageImageUrl(): string | null {
		if (this.panoramaImageUrl == null) return null;
		return this.panoramaImageUrl;
	}

	getLat() {
		return this.gps.lat;
	}
	getLng() {
		return this.gps.lon;
	}

	getCountry(): string {
		switch (this.countryCode) {
			case 'AT':
				return 'Austria';
				break;
			case 'CH':
				return 'Switzerland';
				break;
			case 'CZ':
				return 'Czech Rep';
				break;
			case 'DE':
				return 'Germany';
				break;
			case 'HR':
				return 'Croatia';
				break;
			case 'HU':
				return 'Hungary';
				break;
			case 'IT':
				return 'Italy';
				break;
			case 'NL':
				return 'Netherlands';
				break;
			case 'PL':
				return 'Poland';
				break;
			case 'SI':
				return 'Slovenia';
				break;
			case 'SK':
				return 'Slovakia';
				break;
			case 'TR':
				return 'Turkey';
				break;
			case 'BE':
				return 'Belgium';
				break;
			case 'RS':
				return 'Serbia';
				break;
			case 'FR':
				return 'France';
				break;
			case 'DK':
				return 'Denmark';
				break;
			case 'BA':
				return 'Bosnia and Herzegovina';
				break;
			case 'TH':
				return 'Thailand';
				break;
			default:
				return '';
				break;
		}
	}
}
