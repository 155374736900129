import { NeighborhoodInterface } from '../interfaces/neightborhood.interface';

export class NeighborhoodClass {
	description: string;
	imageUrl: string;
	name: string;
	position: number;
	tags: string[];

	constructor(data: NeighborhoodInterface) {
		this.description = data.description;
		this.imageUrl = data['image-url'];
		this.name = data.name;
		this.position = data.position;
		this.tags = data.tags;
	}

	fromJson(json: NeighborhoodInterface): void {
		this.description = json.description;
		this.imageUrl = json['image-url'];
		this.name = json.name;
		this.position = json.position;
		this.tags = json.tags;
	}
}
