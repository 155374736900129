import { MarketingCityInterface } from '../interfaces/marketing_city.interface';
import { MarketingCityInfoInterface } from './../interfaces/marketing_cityinfo.interface';
import { ImageClass } from './image.class';
import { NeighborhoodClass } from './neighborhood.class';
import { SiteClass } from './sites.class';

export class MarketingCityClass {
	id!: string;
	type!: string;
	bestTravelDateFrom!: string;
	bestTravelDateTo!: string;
	cityMainImage!: string;
	countryCode!: string;
	currency!: string;
	description!: string;
	destinationName!: string;
	imageAltText!: string;
	images!: ImageClass[];
	cityGalleryImages!: ImageClass[];
	metadata!: string;
	optionalTopSites!: SiteClass[];
	requiredTopSites!: SiteClass[];
	summary!: string;
	tags!: string[];
	titleTag!: string;
	topNeighborhoods!: NeighborhoodClass[];
	wizardName!: string;
	wizardTitleTag!: string;

	fromJson(json: MarketingCityInterface): void {
		this.id = json.id;
		this.type = json.type;

		this.bestTravelDateFrom = json?.attributes['best-travel-date-from'];
		this.bestTravelDateTo = json?.attributes['best-travel-date-to'];
		this.cityMainImage = json?.attributes['city-main-image'];
		this.countryCode = json?.attributes['country-code'];
		this.currency = json?.attributes['currency'];
		this.description = json?.attributes['description'];
		this.destinationName = json?.attributes['destination-name'];
		this.imageAltText = json?.attributes['image-alt-text'];
		this.images = json?.attributes.images.map((image) => new ImageClass(image));
		this.cityGalleryImages =
			json?.attributes['city-gallery-images'] && json?.attributes['city-gallery-images'].length > 0
				? json?.attributes['city-gallery-images'].map((image) => new ImageClass(image))
				: [];
		this.metadata = json?.attributes['metadata'];
		this.optionalTopSites = json?.attributes['optional-top-sites'].map((site) => new SiteClass(site));
		this.requiredTopSites = json?.attributes['required-top-sites'].map((site) => new SiteClass(site));
		this.topNeighborhoods = json?.attributes['top-neighborhoods'].map((site) => new NeighborhoodClass(site));
		this.summary = json?.attributes['summary'];
		this.tags = json?.attributes['tags'];
		this.titleTag = json?.attributes['title-tag'];
		this.wizardName = json?.attributes['wizard-name'];
		this.wizardTitleTag = json?.attributes['wizard-title-tag'];
	}

	fromJsonDetail(json: MarketingCityInfoInterface): void {
		this.bestTravelDateFrom = json['best-travel-date-from'];
		this.bestTravelDateTo = json['best-travel-date-to'];
		this.cityMainImage = json['city-main-image'];
		this.countryCode = json['country-code'];
		this.currency = json['currency'];
		this.description = json['description'];
		this.destinationName = json['destination-name'];
		this.imageAltText = json['image-alt-text'];
		this.images = json.images.map((image) => new ImageClass(image));
		this.cityGalleryImages =
			json['city-gallery-images'] && json['city-gallery-images'].length > 0
				? json['city-gallery-images'].map((image) => new ImageClass(image))
				: [];
		this.metadata = json['metadata'];
		this.optionalTopSites = json['optional-top-sites'].map((site) => new SiteClass(site));
		this.requiredTopSites = json['required-top-sites'].map((site) => new SiteClass(site));
		this.topNeighborhoods = json['top-neighborhoods'].map((site) => new NeighborhoodClass(site));
		this.summary = json['summary'];
		this.tags = json['tags'];
		this.titleTag = json['title-tag'];
		this.wizardName = json['wizard-name'];
		this.wizardTitleTag = json['wizard-title-tag'];
	}

	bestDateFrom(): string {
		const date = new Date(this.bestTravelDateFrom).getMonth();
		const monthNames = [
			'January',
			'February',
			'March',
			'April',
			'May',
			'June',
			'July',
			'August',
			'September',
			'October',
			'November',
			'December',
		];
		return monthNames[date];
	}
	bestDateTo(): string {
		const date = new Date(this.bestTravelDateTo).getMonth();
		const monthNames = [
			'January',
			'February',
			'March',
			'April',
			'May',
			'June',
			'July',
			'August',
			'September',
			'October',
			'November',
			'December',
		];
		return monthNames[date];
	}
	getImages(): ImageClass[] {
		const images = this.images;
		if (images == undefined) return images;
		images.sort((b, a) => (a.position < b.position ? 1 : -1));
		return images;
	}
	getFewImages(): ImageClass[] {
		let images = this.images;
		if (images == undefined) return images;
		images.sort((b, a) => (a.position < b.position ? 1 : -1));
		images = images.slice(1, 5);
		return images;
	}
	getDescription(): string {
		return this.summary;
	}
	getDescription2(): string {
		return this.description;
	}

	getMainImage(width: number, height: number): string {
		if (!this.cityMainImage || typeof this.cityMainImage !== 'string') return '';

		// Extract the base URL and parameters
		const [baseUrl, params] = this.cityMainImage.split('/upload/');
		const parts = params.split('/');
		const transformationsIndex = parts.findIndex((part) => part.match(/v[0-9]+/));

		// Extract transformations and the rest of the URL
		let transformations = parts.slice(0, transformationsIndex).join(',');
		let restOfUrl = parts.slice(transformationsIndex).join('/');

		const supportedFormatsRegex = /\.(png|jpg|jpeg)$/i;

		// Replace the supported image format with .webp
		restOfUrl = restOfUrl.replace(supportedFormatsRegex, '.webp');

		// Split transformations into an array
		const transformationArray = transformations.split(',');

		// Define new transformations with specified width and height
		const newTransformations = [`f_auto`, `fl_lossy`, `q_auto`, `w_${width}`, `h_${height}`];

		// Map to replace existing width and height transformations
		const updatedTransformations = transformationArray
			.map((trans) => {
				if (trans.startsWith('w_') || trans.startsWith('h_')) {
					return null; // Mark for removal
				}
				return trans;
			})
			.filter((trans) => trans !== null); // Remove existing width/height

		// Merge transformations ensuring unique values
		const mergedTransformations = new Set([...updatedTransformations, ...newTransformations]);

		// Reconstruct transformations string
		transformations = Array.from(mergedTransformations).join(',');

		// Construct and return the new URL
		return `${baseUrl}/upload/${transformations}/${restOfUrl}`;
	}

	getCountry(): string {
		switch (this.countryCode) {
			case 'AT':
				return 'Austria';
				break;
			case 'CH':
				return 'Switzerland';
				break;
			case 'CZ':
				return 'Czech Rep';
				break;
			case 'DE':
				return 'Germany';
				break;
			case 'HR':
				return 'Croatia';
				break;
			case 'HU':
				return 'Hungary';
				break;
			case 'IT':
				return 'Italy';
				break;
			case 'NL':
				return 'Netherlands';
				break;
			case 'PL':
				return 'Poland';
				break;
			case 'SI':
				return 'Slovenia';
				break;
			case 'SK':
				return 'Slovakia';
				break;
			case 'TR':
				return 'Turkey';
				break;
			case 'BE':
				return 'Belgium';
				break;
			case 'RS':
				return 'Serbia';
				break;
			case 'FR':
				return 'France';
				break;
			case 'DK':
				return 'Denmark';
				break;
			case 'BA':
				return 'Bosnia and Herzegovina';
				break;
			case 'TH':
				return 'Thailand';
				break;
			default:
				return '';
				break;
		}
	}
}
