import { PhoneInterface } from './../interfaces/phone.interface';

export class PhoneClass {
	name: string;
	number: string;

	constructor(data: PhoneInterface) {
		this.name = data.name;
		this.number = data.number;
	}
}
