import { CityConnectionInterface } from '../interfaces/city_connection.interface';
import { CityClass } from './city.class';

declare let google: any;

export class CityConnectionClass {
	id!: string;
	type!: string;
	travelTime!: number;
	travelMethod!: string;
	endingCity!: CityClass;
	marker: any;

	fromJson(json: CityConnectionInterface): void {
		const end = new CityClass();
		end.fromJson(json['ending-city']);
		this.id = json.id;
		this.type = json.type;

		this.travelTime = json?.attributes['travel-time'];
		this.travelMethod = json?.attributes['travel-method'];
		this.endingCity = end;
	}

	isLand(): boolean {
		return this.travelMethod === 'land';
	}

	isAir(): boolean {
		return !this.isLand();
	}

	getTravelMethod(): string {
		if (this.isLand()) {
			return 'land';
		}
		return 'plane';
	}

	setCity(city: CityClass): void {
		this.endingCity = city;
	}

	getCity(): CityClass {
		return this.endingCity;
	}

	getTravelTime(): string {
		return this.transform();
	}

	transform(): string {
		const temp = this.travelTime * 60;
		const hours = Math.floor(temp / 3600);
		const minutes: number = this.travelTime - 60 * hours;
		return hours + 'h ' + minutes + 'm';
	}

	setMarker(marker: any): void {
		this.marker = marker;
	}

	bounceMarker(): void {
		if (this.marker) {
			this.marker.setAnimation(google.maps.Animation.BOUNCE);
		}
	}

	stopBounceMarker(): void {
		if (this.marker) {
			this.marker.setAnimation(null);
		}
	}
}
