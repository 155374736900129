import { GpsInterface } from '../interfaces/gps.interface';

export class GpsClass {
	lat: number;
	lon: number;

	constructor(data: GpsInterface) {
		this.lat = data.lat;
		this.lon = data.lon;
	}

	fromJson(json: GpsInterface): void {
		this.lat = json.lat;
		this.lon = json.lon;
	}
}
