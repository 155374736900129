export const environment = {
	production: false,
	apiUrl: 'https://api-staging.gorealtravel.com/api/v2',
	apiBaseUrl: 'https://api-staging.gorealtravel.com/api',
	australiaNumber: '+61 8 7200 1488',
	europeNumber: '+420 777 473 007',
	usaAndCanadaNumber: '+1 312-985-7929',
	backendUrl: 'https://staging.gorealtravel.com',
	frontendUrl: 'https://web-staging.gorealtravel.com',
	googleClientId: '171313739507-g6a225dekhbubqrmsoqffb9eqib8kohb.apps.googleusercontent.com',
	appleClientId: 'com.gorealtravel.GoRealTravel.applelogin',
};
