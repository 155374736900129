import { SiteInterface } from '../interfaces/site.interface';

export class SiteClass {
	altText: string;
	altText2: string;
	description: string;
	image2: string;
	imageUrl: string;
	name: string;
	position: number;
	tags: string[];
	thumbnail: string;

	constructor(data: SiteInterface) {
		this.altText = data['alt-text'];
		this.altText2 = data['alt-text-2'];
		this.description = data.description;
		this.image2 = data['image-2'];
		this.imageUrl = data['image-url'];
		this.name = data.name;
		this.position = data.position;
		this.tags = data.tags;
		this.thumbnail = data.thumbnail;
	}

	fromJson(json: SiteInterface): void {
		this.altText = json['alt-text'];
		this.altText2 = json['alt-text-2'];
		this.description = json.description;
		this.image2 = json['image-2'];
		this.imageUrl = json['image-url'];
		this.name = json.name;
		this.position = json.position;
		this.tags = json.tags;
		this.thumbnail = json.thumbnail;
	}

	getMainImage(width: number, height: number): string {
		if (!this.imageUrl || typeof this.imageUrl !== 'string') return '';

		// Extract the base URL and parameters
		const [baseUrl, params] = this.imageUrl.split('/upload/');
		const parts = params.split('/');
		const transformationsIndex = parts.findIndex((part) => part.match(/v[0-9]+/));

		// Extract transformations and the rest of the URL
		let transformations = parts.slice(0, transformationsIndex).join(',');
		let restOfUrl = parts.slice(transformationsIndex).join('/');

		const supportedFormatsRegex = /\.(png|jpg|jpeg)$/i;

		// Replace the supported image format with .webp
		restOfUrl = restOfUrl.replace(supportedFormatsRegex, '.webp');

		// Split transformations into an array
		const transformationArray = transformations.split(',');

		// Define new transformations with specified width and height
		const newTransformations = [`f_auto`, `fl_lossy`, `q_auto`, `w_${width}`, `h_${height}`];

		// Map to replace existing width and height transformations
		const updatedTransformations = transformationArray
			.map((trans) => {
				if (trans.startsWith('w_') || trans.startsWith('h_')) {
					return null; // Mark for removal
				}
				return trans;
			})
			.filter((trans) => trans !== null); // Remove existing width/height

		// Merge transformations ensuring unique values
		const mergedTransformations = new Set([...updatedTransformations, ...newTransformations]);

		// Reconstruct transformations string
		transformations = Array.from(mergedTransformations).join(',');

		// Construct and return the new URL
		return `${baseUrl}/upload/${transformations}/${restOfUrl}`;
	}
}
