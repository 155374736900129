import { PhoneClass } from './phone.class';

import { LoginInterface } from '../interfaces/login.interface';

export class UserClass {
	id!: string;
	email!: string;
	first_name!: string;
	last_name!: string;
	phones!: any;
	roles!: string[];

	fromJson(json: LoginInterface): void {
		this.id = json.data.id;
		this.email = json.data?.attributes.email;
		this.first_name = json.data?.attributes['first-name'];
		this.last_name = json.data?.attributes['last-name'];
		this.roles = json.data?.attributes['roles'];
		this.phones = json.data?.attributes.phones.map((phone) => new PhoneClass(phone));
	}

	getId(): string {
		return this.id;
	}

	getName(): string {
		return [this.first_name, this.last_name].join(' ');
	}

	getEmail(): string {
		return this.email;
	}

	isAdmin(): boolean {
		if (
			this.roles.indexOf('admin') > -1 ||
			this.roles.indexOf('builder') > -1 ||
			this.roles.indexOf('travel_agent') > -1 ||
			this.roles.indexOf('travel_agent_admin') > -1
		)
			return true;
		return false;
	}
}
