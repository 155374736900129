import { InterestInterface } from '../interfaces/interest.interface';

export class InterestClass {
	id!: string;
	type!: string;
	name!: string;
	key!: string;
	html!: string;
	imageUrl!: string;
	active!: boolean;
	listName!: string;

	fromJson(json: InterestInterface): void {
		this.id = json.id;
		this.type = json.type;

		this.name = json?.attributes['name'];
		this.key = json?.attributes['key'];
		this.html = json?.attributes['html'];
		this.imageUrl = json?.attributes['image-url'];
		this.listName = json?.attributes['list-name'];
		this.active = false;
	}

	getImage(): string {
		return 'http://' + this.imageUrl;
	}
}
