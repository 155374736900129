import { isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { MarketingPage, MarketingPageData } from '../classes/about-us.class';
import { CdnImage, CdnImageResponse } from '../classes/cdn.images';
import { CityClass } from '../classes/city.class';
import { CityConnectionClass } from '../classes/city_connection.class';
import { CountryClass } from '../classes/country.class';
import { Faq, FaqPage } from '../classes/faq';
import { InterestClass } from '../classes/interest.class';
import { Interest, InterestsPage } from '../classes/interests';
import { MarketingCityClass } from '../classes/marketing_city.class';
import { UserClass } from '../classes/user.class';
import { LoginInterface } from '../interfaces/login.interface';
import { UserJourneyService } from './customer-journey.service';
import { GtmService } from './gtm.service';

@Injectable({
	providedIn: 'root',
})
export class NetworkService {
	isBrowser!: boolean;
	user: UserClass = new UserClass();
	token: string | null = null;
	redirectUrl: string | null = null;
	isEurope: boolean = true;
	isAsia: boolean = false;
	ip: string | null = null;
	is_logged_in: boolean = false;
	private cacheDuration: number = 60000; // Cache duration in milliseconds (e.g., 60 seconds)
	isAdmin: boolean | null = null;
	headers: HttpHeaders = new HttpHeaders({
		Accept: 'application/vnd.api+json',
		'Content-Type': 'application/vnd.api+json',
	});
	headersWithHeaders: HttpHeaders = new HttpHeaders({
		Accept: 'application/vnd.api+json',
		'Content-Type': 'application/vnd.api+json',
	});
	url: string = environment.apiUrl;
	apiV3Url: string = environment.apiBaseUrl + '/v3';
	platformId: object;
	constructor(
		private http: HttpClient,
		private gtm: GtmService,
		private userJourneyService: UserJourneyService,

		public router: Router,

		@Inject(PLATFORM_ID) platformId: object,
	) {
		this.platformId = platformId;

		if (this.isBrowser) {
			this.checkSession();
		}
	}

	public checkSession() {
		if (this._getToken()) {
			this._initToken(this._getToken());
		}

		if (this.is_logged_in) {
			this.headers = new HttpHeaders({
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + this._getToken(),
			});
			this.session()
				.then((user) => (this.user = user))
				.catch(() => null);
		}
	}

	private _initToken(token: string | null): void {
		this.is_logged_in = true;
		this.token = token;
	}

	private _setToken(token: string): void {
		this._initToken(token);
		if (isPlatformBrowser(this.platformId)) {
			localStorage.setItem('token', token);
		}
	}

	private _getToken(): string | null {
		if (isPlatformBrowser(this.platformId)) {
			return localStorage.getItem('token');
		}
		return null;
	}

	private _deleteToken(): void {
		if (isPlatformBrowser(this.platformId)) {
			localStorage.removeItem('token');
		}
	}

	resetContinent() {
		if (isPlatformBrowser(this.platformId)) {
			localStorage.removeItem('isAsia');
		}
	}

	setContinents(europe: boolean, asia: boolean) {
		this.isEurope = europe;
		this.isAsia = asia;
		if (isPlatformBrowser(this.platformId)) {
			if (asia) {
				localStorage.setItem('isAsia', 'yes');
			} else {
				localStorage.setItem('isAsia', 'no');
			}
		}
	}

	isAsiaSelected(): string | any {
		if (this.isBrowser) {
			return localStorage.getItem('isAsia');
		}
	}

	isEuropeSelectedAsB(): any {
		if (this.isBrowser) {
			return localStorage.getItem('isAsia') === 'no';
		}
	}

	session(): Promise<UserClass> {
		return new Promise<UserClass>((resolve, reject) => {
			this.http
				.get<LoginInterface>(this.url + '/session', {
					headers: this.headers,
				})
				.subscribe(
					(response) => {
						const user: UserClass = new UserClass();
						user.fromJson(response);
						this.isAdmin = user.isAdmin();
						resolve(user);
					},
					(error) => {
						console.log(error);
						this.is_logged_in = false;
						this.isAdmin = false;
						this._deleteToken();
						reject();
					},
				);
		});
	}

	logout() {
		return new Promise<boolean>((resolve, reject) => {
			this.http
				.delete<LoginInterface>(this.url + '/session', {
					headers: this.headers,
				})
				.subscribe(
					(response) => {
						console.log(response);
						this.is_logged_in = false;
						this.isAdmin = false;
						this._deleteToken();
						this.headers = new HttpHeaders({
							Accept: 'application/vnd.api+json',
							'Content-Type': 'application/vnd.api+json',
						});
						resolve(true);
					},
					(error) => {
						console.log(error);
						this.is_logged_in = false;
						this.isAdmin = false;
						this._deleteToken();
						reject();
					},
				);
		});
	}

	login(loginData: any): Promise<UserClass> {
		return new Promise<UserClass>((resolve, reject) => {
			this.http
				.post<LoginInterface>(
					this.url + '/session',
					{
						data: {
							type: 'users',
							attributes: {
								email: loginData['email'],
								password: loginData['password'],
							},
						},
					},
					{
						headers: this.headers,
						observe: 'response',
					},
				)
				.subscribe(
					(response) => {
						if (response.headers.has('access-token')) {
							const accessToken = response.headers.get('access-token');
							if (accessToken) {
								this._setToken(accessToken);
							}
						}
						if (response.headers.has('redirect-uri')) {
							this.redirectUrl = response.headers.get('redirect-uri');
						}
						const user: UserClass = new UserClass();
						const userData = response.body;
						if (userData) {
							user.fromJson(response.body);
						}
						this.user = user;
						this.isAdmin = user.isAdmin();
						this.headersWithHeaders = new HttpHeaders({
							Accept: 'application/json',
							'Content-Type': 'application/json',
							Authorization: 'Bearer ' + this._getToken(),
						});
						this.gtm.login(user, this.headersWithHeaders);
						resolve(user);
					},
					(error) => {
						const message = this.handleError(error);
						reject(message);
					},
				);
		});
	}

	socialLogin(loginData: any): Promise<UserClass> {
		return new Promise<UserClass>((resolve, reject) => {
			this.http
				.post<LoginInterface>(
					this.url + '/session',
					{
						data: {
							type: 'users',
							attributes: {
								uid: loginData['uid'],
								provider: loginData['provider'],
								email: loginData['email'],
							},
						},
					},
					{
						headers: this.headers,
						observe: 'response',
					},
				)
				.subscribe(
					(response) => {
						if (response.headers.has('access-token')) {
							const accessToken = response.headers.get('access-token');
							if (accessToken) {
								this._setToken(accessToken);
							}
						}
						if (response.headers.has('redirect-uri')) {
							this.redirectUrl = response.headers.get('redirect-uri');
						}
						const user: UserClass = new UserClass();
						const userData = response.body;
						if (userData) {
							user.fromJson(response.body);
						}
						this.user = user;
						this.isAdmin = user.isAdmin();
						this.headersWithHeaders = new HttpHeaders({
							Accept: 'application/json',
							'Content-Type': 'application/json',
							Authorization: 'Bearer ' + this._getToken(),
						});
						this.gtm.login(user, this.headersWithHeaders);
						resolve(user);
					},
					(error) => {
						const message = this.handleError(error);
						reject(message);
					},
				);
		});
	}

	handleError(error: { status: any }) {
		switch (error.status || 0) {
			case 401:
				return 'Please check your email and password.';
			case 404:
				return error;
			default:
				return 'Server is not availalible. Please try again later.';
		}
	}

	getItineraries(): Promise<string[]> {
		return new Promise<string[]>((resolve) => {
			this.http
				.get<any>(this.url + '/itineraries', {
					headers: this.headersWithHeaders,
				})
				.subscribe(
					(response) => {
						if (response.data) {
							const itineraries: string[] = [];

							response.data.forEach((itinerary: any) => {
								itineraries.push(itinerary.id);
							});
							resolve(itineraries);
						}
					},
					(reject) => {
						reject();
					},
				);
		});
	}

	changePassword(loginData: any, token: string): Promise<UserClass> {
		return new Promise<UserClass>((resolve, reject) => {
			this.http
				.put<LoginInterface>(
					this.url + '/passwords/' + token,
					{
						data: {
							type: 'users',
							attributes: {
								password: loginData['password'],
							},
						},
					},
					{
						headers: this.headers,
						observe: 'response',
					},
				)
				.subscribe(
					(response) => {
						if (response.headers.has('access-token')) {
							const accessToken = response.headers.get('access-token');
							if (accessToken) {
								this._setToken(accessToken);
							}
						}
						const user: UserClass = new UserClass();
						const userData = response.body;
						if (userData) {
							user.fromJson(response.body);
						}
						this.user = user;
						this.gtm.login(user, this.headers);
						resolve(user);
					},
					(error) => {
						let message: string = '';
						switch (error.status || 0) {
							case 422:
								message = 'Invalid token or invalid password.';
								break;
							default:
								message = 'Server is not availalible. Please try again later.';
						}
						reject(message);
					},
				);
		});
	}

	resetPassword(loginData: any, token: string): Promise<UserClass> {
		return new Promise<UserClass>((resolve, reject) => {
			this.http
				.put<LoginInterface>(
					this.url + '/passwords/' + token,
					{
						data: {
							type: 'users',
							attributes: {
								password: loginData['password'],
							},
						},
					},
					{
						headers: new HttpHeaders({
							Accept: 'application/vnd.api+json',
							'Content-Type': 'application/vnd.api+json',
						}),
						observe: 'response',
					},
				)
				.subscribe(
					(response) => {
						if (response.headers.has('access-token')) {
							const accessToken = response.headers.get('access-token');
							if (accessToken) {
								this._setToken(accessToken);
							}
						}
						const user: UserClass = new UserClass();
						const userData = response.body;
						if (userData) {
							user.fromJson(response.body);
						}
						this.user = user;
						this.gtm.login(user, this.headers);
						resolve(user);
					},
					(error) => {
						let message: string = '';
						switch (error.status || 0) {
							case 422:
								message = 'Invalid token or invalid password.';
								break;
							default:
								message = 'Server is not availalible. Please try again later.';
						}
						reject(message);
					},
				);
		});
	}

	forgot(loginData: any): Promise<boolean> {
		return new Promise<boolean>((resolve, reject) => {
			this.http
				.post<any>(
					this.url + '/passwords',
					{
						data: {
							type: 'users',
							attributes: {
								email: loginData['email'],
								isGrt: true,
							},
						},
					},
					{
						headers: this.headers,
						observe: 'response',
					},
				)
				.subscribe(
					(response) => {
						console.log(response);
						resolve(true);
					},
					(error) => {
						let message: string = '';
						switch (error.status || 0) {
							case 404:
								message = 'User not found for given email.';
								break;
							default:
								message = 'Server is not availalible. Please try again later.';
						}
						reject(message);
					},
				);
		});
	}

	register(registerData: any): Promise<UserClass> {
		let countryCode: any = null;
		let phoneNumber: any = null;
		if (registerData['phone'] !== null) {
			countryCode = registerData['phone'].countryCode;
			phoneNumber = registerData['phone'].internationalNumber;
		}
		return new Promise<UserClass>((resolve, reject) => {
			this.http
				.post<LoginInterface>(
					this.url + '/users',
					{
						data: {
							type: 'users',
							attributes: {
								email: registerData['email'],
								password: registerData['password'],
								phone: phoneNumber,
								first_name: registerData['first_name'],
								last_name: registerData['last_name'],
								country_code: countryCode,
								sign_up_ip: this.ip,
								sms_opt_in: registerData['smsOptIn'],
								wizard_version: this.userJourneyService.getVersion(),
							},
						},
					},
					{
						headers: this.headers,
						observe: 'response',
					},
				)
				.subscribe(
					(response) => {
						if (response.headers.has('access-token')) {
							const data = response.headers.get('access-token');
							if (data) {
								this._setToken(data);
							}
						}
						const user: UserClass = new UserClass();
						const userData = response.body;
						if (userData) {
							user.fromJson(userData);
						}
						this.user = user;
						this.gtm.register(user);
						resolve(user);
					},
					(error) => {
						let message: string = '';
						switch (error.status || 0) {
							case 401:
								message = 'Please check your form data.';
								break;
							case 422:
								if (error.error.errors[0].detail === 'taken') {
									reject({
										email: {
											taken: 'This email address is already taken',
										},
									});
								}
								message =
									"You already have a user account. Please first sign in by selecting 'Login' just below the green button.";
								break;
							default:
								message = 'Server is not availalible. Please try again later.';
						}
						reject(message);
					},
				);
		});
	}

	socialRegister(registerData: any): Promise<UserClass> {
		let countryCode: any = null;
		let phoneNumber: any = null;
		if (registerData['phone'] !== null) {
			countryCode = registerData['phone'].countryCode;
			phoneNumber = registerData['phone'].internationalNumber;
		}
		return new Promise<UserClass>((resolve, reject) => {
			this.http
				.post<LoginInterface>(
					this.url + '/users',
					{
						data: {
							type: 'users',
							attributes: {
								uid: registerData['uid'].toString(),
								email: registerData['email'],
								phone: phoneNumber,
								country_code: countryCode,
								first_name: registerData['first_name'],
								last_name: registerData['last_name'],
								password: registerData['password'],
								provider: registerData['provider'],
								sign_up_ip: this.ip,
								sms_opt_in: registerData['smsOptIn'],
								wizard_version: this.userJourneyService.getVersion(),
							},
						},
					},
					{
						headers: this.headers,
						observe: 'response',
					},
				)
				.subscribe(
					(response) => {
						if (response.headers.has('access-token')) {
							const data = response.headers.get('access-token');
							if (data) {
								this._setToken(data);
							}
						}
						const user: UserClass = new UserClass();
						const userData = response.body;
						if (userData) {
							user.fromJson(userData);
						}
						this.user = user;
						this.gtm.register(user);
						resolve(user);
					},
					(error) => {
						let message: string = '';
						switch (error.status || 0) {
							case 401:
								message = 'Please check your form data.';
								break;
							case 422:
								if (error.error.errors[0].detail === 'taken') {
									reject({
										email: {
											taken: 'This email address is already taken',
										},
									});
								}
								message =
									"You already have a user account. Please first sign in by selecting 'Login' just below the green button.";
								break;
							default:
								message = 'Server is not availalible. Please try again later.';
						}
						reject(message);
					},
				);
		});
	}

	getIpCliente(): Promise<string> {
		return new Promise<string>((resolve, reject) => {
			this.http.get<any>('https://api.ipify.org/?format=json').subscribe(
				(response) => {
					if (response.ip) {
						this.ip = response.ip;
						resolve(response.ip);
					}
				},
				(error) => {
					reject(error);
				},
			);
		});
		// return this.http.get('http://api.openweathermap.org/data/2.5/weather?lat='+lat+'&lon='+lon+'&APPID=936799a2d524247c57bca073074fc449');
	}

	getTopTrip(id: any): Promise<any> {
		// Adjust the return type as per your data structure
		return new Promise<any>((resolve, reject) => {
			this.http
				.get<any>(this.apiV3Url + '/top-trips/' + id, {
					headers: this.headers,
				})
				.subscribe(
					(response) => {
						if (!response.data.attributes.url) {
							if (response.data) {
								resolve(response); // Resolve with the data part of the response
							} else {
								reject('No data found');
							}
						} else {
							const url = response.data.attributes.url;
							this.router.navigate(['/trips', url.split('/')[2]]);
							this.getTopTrip(url.split('/')[2])
								.then((nestedResponse) => {
									resolve(nestedResponse); // Resolve with the response from the recursive call
								})
								.catch((error) => {
									reject(error); // Forward error from recursive call
								});
						}
					},
					(error) => reject(error.error), // Properly forward the error
				);
		});
	}

	getTopTripCard(): Promise<string[]> {
		return new Promise<string[]>((resolve, reject) => {
			this.http
				.get<any>(this.apiV3Url + '/marketing/trip_cards', {
					headers: this.headers,
				})
				.subscribe(
					(response) => {
						if (response.data) {
							resolve(response.data);
						} else {
							reject('No data found'); // or handle this case as you see fit
						}
					},
					(error) => reject(error), // Properly forward the error
				);
		});
	}

	getAdminPageCms(): Promise<string[]> {
		return new Promise<string[]>((resolve, reject) => {
			this.http
				.get<any>(this.apiV3Url + '/marketing/clp_cms_page', {
					headers: this.headers,
				})
				.subscribe(
					(response) => {
						if (response.data) {
							resolve(response.data);
						} else {
							reject('No data found'); // or handle this case as you see fit
						}
					},
					(error) => reject(error), // Properly forward the error
				);
		});
	}

	getTripMenus(): Promise<string[]> {
		return new Promise<string[]>((resolve, reject) => {
			this.http
				.get<any>(this.apiV3Url + '/marketing/menu_trips', {
					headers: this.headers,
				})
				.subscribe(
					(response) => {
						if (response.data) {
							resolve(response.data);
						} else {
							reject('No data found'); // or handle this case as you see fit
						}
					},
					(error) => reject(error), // Properly forward the error
				);
		});
	}

	getDestinationTopTripCard(id: any): Promise<string[]> {
		return new Promise<string[]>((resolve, reject) => {
			this.http
				.get<any>(this.apiV3Url + `/top-trips/destination/${id}`, {
					headers: this.headers,
				})
				.subscribe(
					(response) => {
						if (response) {
							resolve(response);
						} else {
							reject('No data found'); // or handle this case as you see fit
						}
					},
					(error) => reject(error), // Properly forward the error
				);
		});
	}

	getTopTripList(): Promise<any> {
		const currentUrl = this.apiV3Url + '/top-trips?page[number]=1&page[size]=25';

		return new Promise<any>((resolve, reject) => {
			this.http
				.get<any>(currentUrl, {
					headers: this.headers,
				})
				.subscribe(
					(response) => {
						if (response.data) {
							resolve(response);
						}
					},
					(error) => reject(error),
				);
		});
	}

	getCategoryPageList(): Promise<any> {
		const currentUrl = this.apiV3Url + '/category_list_pages';
		return new Promise<any>((resolve, reject) => {
			this.http
				.get<any>(currentUrl, {
					headers: this.headers,
				})
				.subscribe(
					(response) => {
						if (response.data) {
							resolve(response);
						}
					},
					(error) => reject(error),
				);
		});
	}

	getCategoryPageDetail(slug: string): Promise<any> {
		const currentUrl = this.apiV3Url + '/category_list_pages/' + slug;
		return new Promise<any>((resolve, reject) => {
			this.http
				.get<any>(currentUrl, {
					headers: this.headers,
				})
				.subscribe(
					(response) => {
						if (response.data) {
							resolve(response);
						}
					},
					(error) => reject(error.error),
				);
		});
	}

	getTopTripListFiltered(params: any, cpl: boolean = false): Promise<any> {
		// Extract filter parameters from the 'params' object
		const startingCity = params['starting-city'];
		const endingCity = params['ending-city'];
		const theme = params['themes'];
		const cities = params['cities'];
		const countries = params['countries'];
		const minPrice = params['minPrice'];
		const maxPrice = params['maxPrice'];
		const minDays = params['minDays'];
		const maxDays = params['maxDays'];
		const size = params['size'] || cpl ? 10 : 25;
		const number = params['number'] || 1;

		// Initialize an array to store the query parameters
		const queryParams: Array<string> = [];

		// Conditionally add query parameters based on values presence
		if (startingCity) {
			queryParams.push(`filter[starting_city]=${encodeURIComponent(startingCity)}`);
		}

		if (endingCity) {
			queryParams.push(`filter[ending_city]=${encodeURIComponent(endingCity)}`);
		}

		if (theme) {
			queryParams.push(`filter[themes]=${encodeURIComponent(theme)}`);
		}

		if (cities) {
			queryParams.push(`filter[cities]=${encodeURIComponent(cities)}`);
		}

		if (countries) {
			queryParams.push(`filter[countries]=${encodeURIComponent(countries)}`);
		}

		if (minPrice) {
			queryParams.push(`filter[minPrice]=${encodeURIComponent(minPrice)}`);
		}

		if (maxPrice) {
			queryParams.push(`filter[maxPrice]=${encodeURIComponent(maxPrice)}`);
		}

		if (minDays) {
			queryParams.push(`filter[minDays]=${encodeURIComponent(minDays)}`);
		}

		if (maxDays) {
			queryParams.push(`filter[maxDays]=${encodeURIComponent(maxDays)}`);
		}

		if (size) {
			queryParams.push(`page[size]=${encodeURIComponent(size)}`);
		}

		if (number) {
			queryParams.push(`page[number]=${encodeURIComponent(number)}`);
		}

		// Construct the API URL by joining the query parameters with '&'

		return new Promise<any>((resolve, reject) => {
			this.http
				.get<any>(`${this.apiV3Url}/top-trips?${queryParams.join('&')}`, {
					headers: this.headers,
				})
				.subscribe(
					(response) => {
						if (response.data) {
							resolve(response);
						}
					},
					(error) => reject(error), // Properly forward the error
				);
		});
	}

	getCities(filter = ''): Promise<CityClass[]> {
		const currentUrl = this.url + `/cities${filter}`;
		return new Promise<CityClass[]>((resolve, reject) => {
			this.http.get<any>(currentUrl).subscribe(
				(response) => {
					if (response.data) {
						const cities: CityClass[] = [];

						response.data.forEach((city: any) => {
							const tmpCity: CityClass = new CityClass();
							tmpCity.fromJson(city);
							cities.push(tmpCity);
						});

						resolve(cities);
					}
				},
				(error) => reject(error),
			);
		});
	}

	getCountries(filter = ''): Promise<CountryClass[]> {
		const currentUrl = this.apiV3Url + `/countries${filter}`;
		return new Promise<CountryClass[]>((resolve, reject) => {
			this.http.get<any>(currentUrl).subscribe(
				(response) => {
					if (response.data) {
						const countries: CountryClass[] = [];
						response.data.forEach((city: any) => {
							countries.push(city);
						});
						resolve(countries);
					}
				},
				(error) => reject(error),
			);
		});
	}

	getInterests(): Promise<InterestClass[]> {
		const currentUrl = this.apiV3Url + '/interests?filter[continent]=europe';

		return new Promise<InterestClass[]>((resolve, reject) => {
			this.http.get<any>(currentUrl).subscribe(
				(response) => {
					if (response.data) {
						const interests: InterestClass[] = [];

						response.data.forEach((interest: any) => {
							const tmpInterest: InterestClass = new InterestClass();
							tmpInterest.fromJson(interest);
							interests.push(tmpInterest);
						});

						resolve(interests);
					}
				},
				(error) => {
					reject(error);
				},
			);
		});
	}

	getCity(id: string): Promise<CityClass> {
		const currentUrl = this.url + '/cities/' + id;
		return new Promise<CityClass>((resolve, reject) => {
			this.http.get<any>(currentUrl).subscribe(
				(response) => {
					if (response.data) {
						const city: CityClass = new CityClass();
						city.fromJson(response.data);
						resolve(city);
					}
				},
				(error) => {
					reject(error);
				},
			);
		});
	}

	getMarketingCity(id: string): Promise<MarketingCityClass> {
		return new Promise<MarketingCityClass>((resolve, reject) => {
			this.http.get<any>(this.url + '/marketing/cities/' + id).subscribe(
				(response) => {
					if (response.data) {
						const city: MarketingCityClass = new MarketingCityClass();
						city.fromJson(response.data);
						resolve(city);
					}
				},
				(error) => {
					reject(error);
				},
			);
		});
	}

	getCurrentWeather(lat: string, lon: string): Promise<string> {
		return new Promise<string>((resolve, reject) => {
			this.http
				.get<any>(
					'http://api.openweathermap.org/data/2.5/weather?lat=' +
						lat +
						'&lon=' +
						lon +
						'&units=metric&APPID=936799a2d524247c57bca073074fc449',
				)
				.subscribe(
					(response) => {
						if (response.main) {
							resolve(response.main.temp + '°C, ' + response.weather[0].main);
						}
					},
					(error) => {
						reject(error);
					},
				);
		});
	}

	getCityConnections(data: any): Promise<CityConnectionClass[]> {
		return new Promise<CityConnectionClass[]>((resolve, reject) => {
			this.http
				.get<any>(this.url + '/city_connections', {
					params: {
						'city_ids[]': data,
					},
					headers: this.headers,
					observe: 'response',
				})
				.subscribe(
					(response) => {
						if (response.body) {
							const connections: any = [];
							const responseData = response.body.data;
							const responseIncluded = response.body.included;
							let i = 0;
							if (responseIncluded) {
								responseIncluded.forEach((place: any) => {
									const connection = new CityConnectionClass();
									const dataPlace = {
										id: responseData[i].id,
										type: responseData[i].type,
										attributes: responseData[i].attributes,
										'ending-city': place,
									};
									connection.fromJson(dataPlace);
									connections.push(connection);
									i++;
								});
							}
							// resolve(connections.sort((a, b) => (a.travelTime > b.travelTime) ? 1 : -1));
							resolve(connections);
						}
					},
					(error) => {
						reject(error);
					},
				);
		});
	}

	getCdnImages(): Promise<CdnImage[]> {
		return new Promise<CdnImage[]>((resolve, reject) => {
			const cache = isPlatformBrowser(this.platformId) ? sessionStorage.getItem('marketing_images_v3') : '';
			if (cache) {
				const parsed = JSON.parse(cache) as CdnImageResponse;
				resolve(parsed.data);
				return;
			} else {
				this.http.get<CdnImageResponse>(this.url + '/marketing/web_images').subscribe(
					(response) => {
						if (response) {
							const cacheToSave = JSON.stringify(response);
							if (isPlatformBrowser(this.platformId)) {
								sessionStorage.setItem('marketing_images_v3', cacheToSave);
							}
							resolve(response.data);
						} else {
							reject();
						}
					},
					(error) => {
						reject(error);
					},
				);
			}
		});
	}

	public getCdnImage(name: string, width: number, height: number): Promise<CdnImage> {
		return new Promise<CdnImage>((resolve, reject) => {
			this.getCdnImages().then(
				(response) => {
					const cdnImage = response.find((q) => q.attributes['text-identifier'] === name);
					if (!cdnImage) {
						reject('not_found');
					} else {
						cdnImage.attributes.url = this.transformCdnImage(cdnImage.attributes.url, width, height);
						cdnImage.attributes.urlWebP = this.transformCdnImage(cdnImage.attributes.url, width, height);
						resolve(cdnImage);
					}
				},
				(error) => reject(error),
			);
		});
	}

	private transformCdnImage(url: string, width: number, height: number) {
		// Extract the base URL and parameters
		if (!url || typeof url !== 'string') return '';

		const [baseUrl, params] = url.split('/upload/');
		const parts = params.split('/');
		const transformationsIndex = parts.findIndex((part) => part.match(/v[0-9]+/));

		// Extract transformations and the rest of the URL
		let transformations = parts.slice(0, transformationsIndex).join(',');
		let restOfUrl = parts.slice(transformationsIndex).join('/');

		const supportedFormatsRegex = /\.(png|jpg|jpeg)$/i;

		// Replace the supported image format with .webp
		restOfUrl = restOfUrl.replace(supportedFormatsRegex, '.webp');

		// Split transformations into an array
		const transformationArray = transformations.split(',');

		// Define new transformations with specified width and height
		const newTransformations = [`f_auto`, `fl_lossy`, `q_auto`, `w_${width}`, `h_${height}`];

		// Map to replace existing width and height transformations
		const updatedTransformations = transformationArray
			.map((trans) => {
				if (trans.startsWith('w_') || trans.startsWith('h_')) {
					return null; // Mark for removal
				}
				return trans;
			})
			.filter((trans) => trans !== null); // Remove existing width/height

		// Merge transformations ensuring unique values
		const mergedTransformations = new Set([...updatedTransformations, ...newTransformations]);

		// Reconstruct transformations string
		transformations = Array.from(mergedTransformations).join(',');

		// Construct and return the new URL
		return `${baseUrl}/upload/${transformations}/${restOfUrl}`;
	}

	public faq(): Promise<Faq[]> {
		return new Promise<Faq[]>((resolve, reject) => {
			if (isPlatformBrowser(this.platformId)) {
				const cache = sessionStorage.getItem('faq_page');
				if (cache) {
					const parsed = JSON.parse(cache) as Faq[];
					resolve(parsed);
					return;
				} else {
					this.http.get<FaqPage>(this.url + '/marketing/faqs').subscribe(
						(response) => {
							if (response) {
								const cacheToSave = JSON.stringify(response.data);
								sessionStorage.setItem('faq_page', cacheToSave);
								resolve(response.data);
							} else {
								reject();
							}
						},
						(error) => {
							reject(error);
						},
					);
				}
			}
		});
	}

	public marketingPage(name: string): Promise<MarketingPageData> {
		{
			return new Promise<MarketingPageData>((resolve, reject) => {
				const cache = isPlatformBrowser(this.platformId) ? sessionStorage.getItem('marketing_pages') : '';
				if (cache && cache?.length > 0) {
					const parsed = JSON.parse(cache) as MarketingPageData[];
					const pageData = parsed.find((q) => q.attributes['page-type'] === name);
					if (pageData) {
						resolve(pageData);
					}
					return;
				} else {
					this.http.get<MarketingPage>(this.url + '/marketing/pages').subscribe(
						(response) => {
							if (response) {
								const cacheToSave = JSON.stringify(response.data);
								if (isPlatformBrowser(this.platformId)) {
									sessionStorage.setItem('marketing_pages', cacheToSave);
								}
								const data = response.data.find((q) => q.attributes['page-type'] === name);
								if (data) {
									resolve(data);
								}
							} else {
								reject();
							}
						},
						(error) => {
							reject(error);
						},
					);
				}
			});
		}
	}

	public interests(): Promise<Interest[]> {
		return new Promise<Interest[]>((resolve, reject) => {
			if (isPlatformBrowser(this.platformId)) {
				const cache = sessionStorage.getItem('interests_page_x');
				if (cache) {
					const parsed = JSON.parse(cache) as Interest[];
					resolve(parsed);
					return;
				} else {
					this.http.get<InterestsPage>(this.url + '/interests').subscribe(
						(response) => {
							if (response) {
								const cacheToSave = JSON.stringify(response.data);
								sessionStorage.setItem('interests_page_x', cacheToSave);
								resolve(response.data);
							} else {
								reject();
							}
						},
						(error) => {
							reject(error);
						},
					);
				}
			}
		});
	}
}
