import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserClass } from '@classes/user.class';
import { environment } from '@environments/environment';

declare let window: any;

@Injectable({
	providedIn: 'root',
})
export class GtmService {
	url: string = environment.apiUrl;

	constructor(private http: HttpClient) {}

	register(user: UserClass): void {
		this._send('new', user);
	}

	login(user: UserClass, headers: HttpHeaders): void {
		this._getUserItineraryIds(headers)
			.then((ids) => {
				if (ids.length) {
					this._send('returning', user, ids.join(', '));
				} else {
					this._send('new', user);
				}
			})
			.catch((e) => {
				console.error(e);
			});
	}

	private _getUserItineraryIds(headers: HttpHeaders): Promise<string[]> {
		return new Promise<string[]>((resolve, reject) => {
			this.http.get<any>(this.url + '/itineraries', { headers }).subscribe(
				(response) => {
					if (response.data.length) {
						resolve(response.data.map(({ id }: { id: string }) => id));
					}

					resolve([]);
				},
				(error) => {
					reject(error);
				},
			);
		});
	}

	private _send(type: string, user: UserClass, itineraryId: string = '') {
		window.dataLayer.push({
			event: 'form_submit',
			itineraryId,
			clientEmail: user.getEmail(),
			clientName: user.getName(),
			userId: user.getId(),
			userType: type,
		});
	}
}
