import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
	providedIn: 'root',
})
export class UserJourneyService {
	constructor(private cookieService: CookieService) {}

	private flow: any = {
		B: ['/trip/where-b', '/trip/interests-b', '/trip/logistics-b', '/trip/sign-up-b', '/ready-b'],
	};

	public nextJourney(current: string) {
		const currentVersion = this.getVersion();
		const fullLink = `${current}-${currentVersion.toLowerCase()}`;
		const flow = this.flow[currentVersion] as string[];
		const indexOfCurrent = flow.indexOf(fullLink);
		return flow[indexOfCurrent + 1];
	}

	public backourney(current: string) {
		const currentVersion = this.getVersion();
		const fullLink = `${current}-${currentVersion.toLowerCase()}`;
		const flow = this.flow[currentVersion] as string[];
		const indexOfCurrent = flow.indexOf(fullLink);
		return flow[indexOfCurrent - 1];
	}

	public firstStep() {
		let currentVersion = this.getVersion();
		if (!currentVersion) {
			currentVersion = 'B';
		}
		const flow = this.flow[currentVersion] as string[];
		return flow[0];
	}

	public setVersion(version: string) {
		this.cookieService.delete('JOURNEY_VERSION');
		const date = new Date();
		date.setDate(date.getDate() + 7);
		this.cookieService.set('JOURNEY_VERSION', version, date);
	}

	public getVersion() {
		return this.cookieService.get('JOURNEY_VERSION');
	}

	public showDayCountInSummaryFooter() {
		return this.cookieService.get('JOURNEY_VERSION') === 'A';
	}

	homePage() {
		return '/';
	}
}
