import { ImageInterface } from '../interfaces/image.interface';

export class ImageClass {
	displayName: string;
	position: number;
	url: string;

	constructor(data: ImageInterface) {
		this.displayName = data['display-name'];
		this.position = data.position;
		this.url = data.url;
	}

	fromJson(json: ImageInterface): void {
		this.displayName = json['display-name'];
		this.position = json.position;
		this.url = json.url;
	}

	getNewUrl(width: number, height: number): string {
		if (!this.url || typeof this.url !== 'string') return '';

		// Extract the base URL and parameters
		const [baseUrl, params] = this.url.split('/upload/');
		const parts = params.split('/');
		const transformationsIndex = parts.findIndex((part) => part.match(/v[0-9]+/));

		// Extract transformations and the rest of the URL
		let transformations = parts.slice(0, transformationsIndex).join(',');
		let restOfUrl = parts.slice(transformationsIndex).join('/');

		// Split transformations into an array
		const transformationArray = transformations.split(',');

		// Define new transformations with specified width and height
		const newTransformations = [`f_auto`, `fl_lossy`, `q_auto`, `w_${width}`, `h_${height}`];

		// Map to replace existing width and height transformations
		const updatedTransformations = transformationArray
			.map((trans) => {
				if (trans.startsWith('w_') || trans.startsWith('h_')) {
					return null; // Mark for removal
				}
				return trans;
			})
			.filter((trans) => trans !== null); // Remove existing width/height

		// Merge transformations ensuring unique values
		const mergedTransformations = new Set([...updatedTransformations, ...newTransformations]);

		// Reconstruct transformations string
		transformations = Array.from(mergedTransformations).join(',');

		const supportedFormatsRegex = /\.(png|jpg|jpeg)$/i;

		// Replace the supported image format with .webp
		restOfUrl = restOfUrl.replace(supportedFormatsRegex, '.webp');

		// Construct and return the new URL
		return `${baseUrl}/upload/${transformations}/${restOfUrl}`;
	}
}
